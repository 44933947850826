<template>
  <div class="dark:bg-gray-800 dark:text-white bg-white pt-4 sm:pt-10 lg:pt-12">
    <footer class="max-w-screen-2xl px-4 md:px-8 mx-auto">
      <div
        class="
          footer-content
          flex flex-col
          md:flex-row
          justify-between
          items-center
          border-t border-b
          gap-4
          py-6
        "
      >
        <!-- nav - start -->
        <nav
          class="
            flex flex-wrap
            justify-center
            md:justify-start
            gap-x-4 gap-y-2
            md:gap-6
          "
        >
          <a
            href="https://app.yup.io"
            class="
              text-gray-500
              hover:text-indigo-500
              active:text-indigo-600
              transition
              duration-100
            "
            >YUP APP</a
          >
          <a
            href="https://docs.yup.io"
            class="
              text-gray-500
              hover:text-indigo-500
              active:text-indigo-600
              transition
              duration-100
            "
            >YUP Docs</a
          >
          <a
            href="https://yup.live/"
            class="
              text-gray-500
              hover:text-indigo-500
              active:text-indigo-600
              transition
              duration-100
            "
            >YUP Live</a
          >
          <a
            href="https://forum.yup.io"
            class="
              text-gray-500
              hover:text-indigo-500
              active:text-indigo-600
              transition
              duration-100
            "
            >YUP Forum</a
          >
          <a
            href="https://yup.finanace"
            class="
              text-gray-500
              hover:text-indigo-500
              active:text-indigo-600
              transition
              duration-100
            "
            >YUP Finance</a
          >
        </nav>
        <!-- nav - end -->

        <!-- social - start -->
        <div class="flex gap-4">
          <a
            href="https://www.instagram.com/yup.io_/"
            target="_blank"
            class="
              text-gray-400
              hover:text-gray-500
              active:text-gray-600
              transition
              duration-100
            "
          >
            <svg
              class="w-5 h-5"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
              />
            </svg>
          </a>

          <a
            href="https://twitter.com/yup_io"
            target="_blank"
            class="
              text-gray-400
              hover:text-gray-500
              active:text-gray-600
              transition
              duration-100
            "
          >
            <svg
              class="w-5 h-5"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
              />
            </svg>
          </a>
          <a
            href="https://github.com/Yup-io"
            target="_blank"
            class="
              text-gray-400
              hover:text-gray-500
              active:text-gray-600
              transition
              duration-100
            "
          >
            <svg
              class="w-5 h-5"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
              />
            </svg>
          </a>
        </div>
        <!-- social - end -->
      </div>

      <div class="text-gray-400 text-sm text-center py-8">
        <p>
          {{ year }} - TechStack Vue3, Oruga, Tailwind, Deno, SupaBase,
          CloudFlarePages, Ethers.js
        </p>
        Hosted and serviced by Cloudflare
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          width="16"
          height="16"
          style="display: inline-block"
        >
          <path
            d="M8.16 23h21.177v-5.86l-4.023-2.307-.694-.3-16.46.113z"
            fill="#fff"
          />
          <path
            d="M22.012 22.222c.197-.675.122-1.294-.206-1.754-.3-.422-.807-.666-1.416-.694l-11.545-.15c-.075 0-.14-.038-.178-.094s-.047-.13-.028-.206c.038-.113.15-.197.272-.206l11.648-.15c1.38-.066 2.88-1.182 3.404-2.55l.666-1.735a.38.38 0 0 0 .02-.225c-.75-3.395-3.78-5.927-7.4-5.927-3.34 0-6.17 2.157-7.184 5.15-.657-.488-1.5-.75-2.392-.666-1.604.16-2.9 1.444-3.048 3.048a3.58 3.58 0 0 0 .084 1.191A4.84 4.84 0 0 0 0 22.1c0 .234.02.47.047.703.02.113.113.197.225.197H21.58a.29.29 0 0 0 .272-.206l.16-.572z"
            fill="#f38020"
          />
          <path
            d="M25.688 14.803l-.32.01c-.075 0-.14.056-.17.13l-.45 1.566c-.197.675-.122 1.294.206 1.754.3.422.807.666 1.416.694l2.457.15c.075 0 .14.038.178.094s.047.14.028.206c-.038.113-.15.197-.272.206l-2.56.15c-1.388.066-2.88 1.182-3.404 2.55l-.188.478c-.038.094.028.188.13.188h8.797a.23.23 0 0 0 .225-.169A6.41 6.41 0 0 0 32 21.106a6.32 6.32 0 0 0-6.312-6.302"
            fill="#faae40"
          />
        </svg>
      </div>
    </footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterTemplate",
  components: {},
  setup() {
    const year = new Date().getFullYear();

    return {
      year,
    };
  },
});
</script>

<style lang="scss"></style>
